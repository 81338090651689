import { FunctionComponent, useRef } from 'react';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from 'react-query';

const QueryClientProvider: FunctionComponent = ({ children }) => {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <ReactQueryClientProvider client={queryClientRef.current}>
      {children}
    </ReactQueryClientProvider>
  );
};

export default QueryClientProvider;
