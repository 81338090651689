import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, mainnet } from 'wagmi';
import { goerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

import { isQA } from '~/constants';

const chainToConnect = isQA ?
  goerli :
  mainnet;

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [chainToConnect],
  [
    alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_KEY! }),
    infuraProvider({ apiKey: process.env.NEXT_PUBLIC_INFURA_ID! }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Peacefall',
  projectId: '5a660e0e2eb325601f47413b5861fad0',
  chains,
});

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
