import { useEffect } from 'react';

const KONAMI_IMAGE_SRC = '/img/ninja-jump.gif';
const KONAMI_IMAGE_WIDTH = 250;
const KONAMI_CODE_SEQUENCE = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
let KONAMI_CODE_POS = 0;
const KONAMI_START_LEFT = true;
let KONAMI_CODE_ACTIVATED;

function konamiCheck(key, cb) {
  if (
    KONAMI_CODE_ACTIVATED &&
    KONAMI_CODE_SEQUENCE[KONAMI_CODE_SEQUENCE.length - 1] === key
  ) {
    cb.call();
  } else if (KONAMI_CODE_SEQUENCE[KONAMI_CODE_POS] === key) {
    KONAMI_CODE_ACTIVATED = false;
    KONAMI_CODE_POS++;

    if (KONAMI_CODE_POS === KONAMI_CODE_SEQUENCE.length) {
      cb.call();
      KONAMI_CODE_ACTIVATED = true;
      KONAMI_CODE_POS = 0;
    }
  } else {
    KONAMI_CODE_ACTIVATED = false;
    KONAMI_CODE_POS = 0;
  }
}

function konamiMove() {
  let timer;
  const container = document.body;
  const height = container.offsetHeight;
  const width = container.offsetWidth;
  const img = document.createElement('img');
  let pos = KONAMI_START_LEFT ? 0 : width;
  const _offScreen = function(pos) {
    return KONAMI_START_LEFT ? pos > width : pos <= 0;
  };

  container.style.position = 'relative';
  container.style.overflowX = 'hidden';

  img.src = KONAMI_IMAGE_SRC;
  img.className = 'konami';
  img.width = KONAMI_IMAGE_WIDTH;
  img.style.position = 'fixed';
  img.style.bottom = '0';
  img.style.left = '0';
  img.style.left = '0';
  img.style.transform = 'translateX(' + pos + 'px)';

  img.onload = function() {
    clearInterval(timer);
    window.scrollTo(0, height);
    container.appendChild(img);
    timer = setInterval(function() {
      pos = KONAMI_START_LEFT ? pos + 3 : pos - 3;
      img.style.transform = 'translateX(' + pos + 'px)';
      if (_offScreen(pos)) {
        clearInterval(timer);
        container.removeChild(img);
      }
    }, 17);
  };
}

export default function Konami() {
  function konamiHandler(event) {
    const key = parseInt(event.which, 10);
    konamiCheck(key, konamiMove);
  }

  useEffect(() => {
    document.addEventListener('keydown', konamiHandler);
    return () => {
      document.removeEventListener('keydown', konamiHandler);
    };
  }, []);
  // could turn this into a hook..
  return <></>;
}
